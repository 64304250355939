/* Start default configurations */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: transparent;
}

:root {
  --pink1-color: #EA7878;
  --pink2-color: #EA9292;
  --pink3-color: #FCB1B1;
  --pink4-color: #FFDCDC;
  --pink5-color: #FFEAEA;
  --green1-color: #7BBA87;
  --green2-color: #95C47E;
  --green3-color: #AFCE75;
  --green4-color: #C9D86C;
  --green5-color: #E1F587;
  --black1-color: #34322D;
  --black2-color: #645F53;
  --black3-color: #AFABA1;
  --black4-color: #DBD7CC;
  --black5-color: #F7F4ED;
  --white-color: #FFFFFF;
}

body { 
  color: var(--black1-color);
  background-color:  var(--black5-color);
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* End default configurations */

/* Start of color styles */
/* Dark themes */
.chooseBackground1 {
  background-image: linear-gradient(#34322D, #645F53);
}

.chooseBackground2 {
  background-image: linear-gradient(#34322D, #AFABA1);
}

.chooseBackground3 {
  background-image: linear-gradient(#34322D, #DBD7CC);
}

.chooseBackground4 {
  background-image: linear-gradient(#34322D, #F7F4ED);
}

.chooseBackground5 {
  background-image: linear-gradient(#34322D, #FFFFFF);
}

/* Light themes */
.chooseBackground6 {
  background-image: linear-gradient(#FFFFFF, #F7F4ED);
}

.chooseBackground7 {
  background-image: linear-gradient(#FFFFFF, #DBD7CC);
}

.chooseBackground8 {
  background-image: linear-gradient(#FFFFFF, #AFABA1);
}

.chooseBackground9 {
  background-image: linear-gradient(#FFFFFF, #645F53);
}

.chooseBackground10 {
  background-image: linear-gradient(#FFFFFF, #34322D);
}

.setBackgroundWhite {
  background-color: var(--white-color);
}

.colorPink1 {
  color: var(--pink1-color);
}

.colorPink2 {
  color: var(--pink2-color);
}

.colorPink3 {
  color: var(--pink3-color);
}

.colorPink4 {
  color: var(--pink4-color);
}

.colorPink5 {
  color: var(--pink5-color);
}

.colorGreen1 {
  color: var(--green1-color);
}

.colorGreen2 {
  color: var(--green2-color);
}

.colorGreen3 {
  color: var(--green3-color);
}

.colorGreen4 {
  color: var(--green4-color);
}

.colorGreen5 {
  color: var(--green5-color);
}

.colorBlack1 {
  color: var(--black1-color);
}

.colorBlack2 {
  color: var(--black2-color);
}

.colorBlack3 {
  color: var(--black3-color);
}

.colorBlack4 {
  color: var(--black4-color);
}

.colorBlack5 {
  color: var(--black5-color);
}

.colorWhite {
  color: var(--white-color);
}

.backgroundColorBlack1 {
  background-color: var(--black1-color);
}

.backgroundColorBlack2 {
  background-color: var(--black3-color);
}

.backgroundColorBlack3 {
  background-color: var(--black3-color);
}

.backgroundColorBlack4 {
  background-color: var(--black4-color);
}

.backgroundColorBlack5 {
  background-color: var(--black5-color);
}

.backgroundColorPink1 {
  background-color: var(--pink1-color);
}

.backgroundColorPink2 {
  background-color: var(--pink2-color);
}

.backgroundColorPink3 {
  background-color: var(--pink3-color);
}

.backgroundColorPink4 {
  background-color: var(--pink4-color);
}

.backgroundColorPink5 {
  background-color: var(--pink5-color);
}

.backgroundColorGreen1 {
  background-color: var(--green1-color);
}

.backgroundColorGreen2 {
  background-color: var(--green2-color);
}

.backgroundColorGreen3 {
  background-color: var(--green3-color);
}

.backgroundColorGreen4 {
  background-color: var(--green4-color);
}

.backgroundColorGreen5 {
  background-color: var(--green5-color);
}
/* End color styles */

/* Start of text styles */
.headlines {
  position: absolute;
  left: 0;
  bottom: 12vh;
}

.h1 {
  font-size:9rem;
  line-height: 128%;
  /* font-family: "JetBrains Mono", monospace; */
  font-family: "Dancing Script", cursive;
  font-weight: 700;
  font-style: italic;
}

.h2 {
  font-size: 3.6rem;
  line-height: 128%;
  /* font-family: "JetBrains Mono", monospace; */
}

.h3 {
  font-size: 2.8rem;
  line-height: 128%;
}

.is-style-sentence-case {
  text-transform: lowercase;
}

.is-style-sentence-case::first-letter {
  text-transform: uppercase;
}

@keyframes pulse {
  0% {
      opacity: 1;
  }
  50% {
      opacity: 0.4;
  }
  100% {
      opacity: 1;
  }
}

.loading-pulse {
  animation: pulse 2s ease-in-out infinite;
}

/* Optional: Add a smooth transition when the headline appears */
.title {
  transition: opacity 0.3s ease-in-out;
}

.microText {
  font-size: .5rem!important;
}
/* End of text styles */

/* Start of custom styles */
.borderAdd {
  border: 1px solid #000000;
}
/* End of custom styles */

/* Start link styles */
a {
  text-decoration: none;
  font-weight: 700;
  color: var(--pink1-color)!important;
}

a:hover {
  text-decoration: none;
  opacity: .5;
  cursor: pointer;
}

.cursor {
  cursor: pointer!important;
}
/* End default styles */

/* Start nav styles */
.navigation {
  background-color: transparent!important;
  border-radius: 0 0 0.5rem 0.5rem!important;
  height: 5rem!important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1001;
}

.guavaLogo {
  height: auto!important;
  width: auto!important;
}

.guavaMenu {
  position: relative;
  z-index: 1001; 
}

.guavaCloseIcon {
  height: 5rem!important;
}

@keyframes blink-menu {
  0%, 100% {
      opacity: 1;
  }
  50% {
      opacity: 0.5;
  }
}

.menuPath {
  transition: all 0.3s;
  animation-duration: 0.6s;
  animation-timing-function: linear; 
  animation-iteration-count: infinite;
}

/* Move the first path up to line up with the middle one */
.menuIcon:hover .menuPath:nth-child(1) {
  transform: translate(11px, -11px);
  animation-name: blink-menu;
}

/* Middle path remains in place, but still has the blinking animation */
.menuIcon:hover .menuPath:nth-child(2) {
  animation-name: blink-menu;
  animation-delay: 0.6s;
}

/* Move the third path down to line up with the middle one */
.menuIcon:hover .menuPath:nth-child(3) {
  transform: translate(-11px, 11px);
  animation-name: blink-menu;
  animation-delay: 1.2s;
}
/* End nav styles */

/* Start of menu styles */
.menuContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: var(--black1-color);
  z-index: 1001;
  transform: translateY(-100%);
  transition: transform 1.5s ease-in-out;
}

.menuContainer.open {
  transform: translateY(0);
}

.menuContent {
  position: relative;
  z-index: 1000;
  padding: 1rem;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.menu-content::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
/* End of menu styles */

/* Start of border radius styles */
.border1 {
  border-radius: 1rem;
}

.border2 {
  border-radius: 2rem;
}
/* End of border radius styles */

/* Start spacing styles */
.fullHeight {
  height: 100vh!important;
}

.halfHeight {
  height: 50vh!important;
}

.percentFullHeight {
  height: 100%;
}

.imgOverlay {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 1rem;
}

.square-box {
  position: relative;
  width: 100%!important;
  padding-bottom: 100%!important;
}

.square-box > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tall-box {
  position: relative;
  width: 100%!important;
  padding-bottom: 150%!important;
}

.tall-box > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
}

.oneHundredPercentWidth {
  width: 100%!important;
}

.fiftyPercentWidth {
  width: 50%!important;
}

.fixedWidthThreeHundred {
  width: 300px!important;
}
/* End spacing styles */

/* Start form styles */
.selectGuava {
  /* Existing styles */
  border-radius: 1rem!important;
  background-color: var(--pink5-color)!important;
  color: var(--black2-color)!important;
  width: 180px!important;

  /* Custom dropdown arrow */
  appearance: none!important; /* Removes default arrow */
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23your-color-here' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e")!important;
  background-repeat: no-repeat!important;
  background-position: right 0.5rem center!important;
  background-size: 1.5em!important;
  padding-right: 2.5rem!important;

  /* Consistent width for dropdown menu */
  min-width: 180px!important;
}

/* Styling the options when dropdown is open */
.selectGuava option {
  background-color: white!important;
  color: var(--black2-color)!important;
}

/* Styling for when an option is highlighted/selected in the dropdown */
.selectGuava option:hover,
.selectGuava option:focus,
.selectGuava option:active,
.selectGuava option:checked {
  background-color: var(--pink5-color)!important;
  color: var(--black2-color)!important;
}

/* For Webkit browsers (Chrome, Safari) */
.selectGuava::-webkit-select-value {
  color: var(--black2-color)!important;
}

/* Styling the select when it's focused */
.selectGuava:focus {
  outline: none!important;
}

.selectGuava optgroup,
.selectGuava option {
  width: 180px!important;
  min-width: 180px!important;
}
/* End form styles */

/* Start modal styles */
.modal {
  z-index: 9998!important;
}

.modalBackground {
  z-index: 9998!important;
  background-color: var(--green1-color)!important;
  opacity: .8!important;
}

.modalCard {
  z-index: 9999!important;
}
/* End modal styles */

/* Start sizzle video styles */
.videoContainer {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  /* border-radius: 0 0 2rem 2rem; */
}

.videoContainer video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* End sizzle video styles */

/* Start form styles */
input {
  color: var(--black2-color)!important;
  height: 3.5rem!important;
  width: 100%!important;
  border: 2px solid var(--pink1-color)!important;
  border-radius: 1.25rem!important;
  background-color: var(--pink5-color)!important;
  font-size: 1rem!important;
  font-style: normal!important;
  font-weight: 700!important;
}
/* End form styles */

/* Start button styles */
button {
  height: 2.5rem!important;
  width: auto!important;
  min-width: 7.4rem!important;
  background-color: var(--pink3-color)!important;
  color: var(--white-color)!important;
  border: 2px solid var(--white-color)!important;
  font-size: 0.875rem!important;
  font-style: normal!important;
  font-weight: 700!important;
  border-radius: 2rem!important;
  margin: .24rem 0!important;
  padding: 0.5625rem 1.4rem;
  cursor: pointer;
}

button:hover {
  background-color: var(--black4-color)!important;
  color: var(--pink1-color);
  border: 2px solid var(--pink1-color);
  transition: .25s ease-out;
  cursor: pointer;
}

.buttonDisabled {
  color: var(--green3-color)!important;
  cursor: not-allowed;
}

/* End button styles */