/* Scoped typing effect styles to only affect HomeStatement */
.home-statement-container .typing-text {
  display: inline;
}

/* Only the cursor should blink */
.home-statement-container .cursor {
  display: inline-block;
  width: 3px;
  height: 1em;
  background-color: #333;
  margin-left: 2px;
  animation: statementCursorBlink 1s step-end infinite;
  vertical-align: text-bottom;
}

@keyframes statementCursorBlink {
  from, to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}